import React, { useState, useRef, useContext } from "react";

const ChatContext = React.createContext();

export const ChatProvider = ({ children }) => {
  const [isPopped, setisPopped] = useState(true);
  const [lang, setlang] = useState("English");
  const [animate, setanimate] = useState(false);
  const [ratingPopup, setratingPopup] = useState(false);
  const [emailPopup, setemailPopup] = useState(false);
  const [popup, setpopup] = useState(false);
  const [activeproduct, setactiveproduct] = useState("LA");
  const [limitExceeded, setlimitExceeded] = useState();
  const [israted, setisrated] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [rating, setrating] = useState(0);
  const user_id = useRef();

  const setProduct = (product) => {
    setMessages(allmessages.current[product]);
    setactiveproduct(product);
  };

  const handlePopup = () => {
    setpopup(!popup);
  };

  const languages = [
    "English",
    "French",
    "Russian",
    "Italian",
    "Chinese",
    "German",
  ];

  let allmessages = useRef({
    LA: [
      {
        id: 1,
        content: `Welcome to Laser Academy support! How may I help you?`,
        role: "assistant",
        Timestamp: new Date(),
      },
    ],
    SPS: [
      {
        id: 1,
        content: `Welcome to SPS support! How may I help you?`,
        role: "assistant",
        Timestamp: new Date(),
      },
    ],
    BB: [
      {
        id: 1,
        content: `Welcome to Blackbeard support! How may I help you?`,
        role: "assistant",
        Timestamp: new Date(),
      },
    ],
    ship : [
      {
        id: 1,
        content: `Please reply with your Order ID to continue. (Psst, it should look like this 18XXXXXX.) `,
        role: "assistant",
        Timestamp: new Date(),
      },
    ],
    ship_msgs_for_bot : [
      {
        id: 1,
        content: `Please reply with your Order ID to continue. (Psst, it should look like this 18XXXXXX.)`,
        role: "assistant",
        Timestamp: new Date(),
      },
    ],
    ship_msgs_for_db : [
      {
        id: 1,
        content: `Please reply with your Order ID to continue. (Psst, it should look like this 18XXXXXX.)`,
        role: "assistant",
        Timestamp: new Date(),
      },
    ]
  });
  const [messages, setMessages] = useState(allmessages.current["LA"]);
  const changeLang = (lang) => {
    setlang(lang);
  };

  return (
    <ChatContext.Provider
      value={{
        isPopped,
        setisPopped,
        lang,
        setlang,
        animate,
        setanimate,
        ratingPopup,
        setratingPopup,
        emailPopup,
        setemailPopup,
        popup,
        setpopup,
        handlePopup,
        languages,
        allmessages,
        messages,
        setMessages,
        changeLang,
        activeproduct,
        user_id,
        setProduct,
        setactiveproduct,
        limitExceeded,
        setlimitExceeded,
        israted,
        setisrated,
        rating,
        setrating,
        feedback,
        setFeedback,
            }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  return useContext(ChatContext);
};
