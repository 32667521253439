import React from "react";

const AlertPopup = ({ children }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="fixed inset-0 bg-black opacity-80  z-10"></div>
      <div className="animate-fade-in bg-white font-semibold shadow p-12 rounded relative z-20">
        {children}
      </div>
    </div>
  );
};

export default AlertPopup;
