import moment from "moment";

export const calculateStats = (chats, setStats, selectedBot) => {
  const filteredChats = chats.filter(chat => chat.bot === selectedBot);
  let totalLength = 0;
  let feedbacks = [];
  let totalRatings = 0;
  let ratingCount = 0;
  const totalChatsPerDay = {};
  const totalChatsPerWeek = {};
  const totalChatsPerMonth = {};
  const weeklyChats = {};

  filteredChats.forEach(chat => {
    const date = moment(chat.start_time, "ddd, DD MMM YYYY HH:mm:ss GMT");
    const dayKey = date.format("YYYY-MM-DD");
    const weekOfYear = date.week();
    const year = date.year();
    const month = date.format("MMM"); // Example: 'Jan', 'Feb', etc.
    const weekOfMonth = Math.ceil(date.date() / 7);

    totalLength += chat.length;

    if (typeof chat.rating === 'number') {
      totalRatings += chat.rating;
      ratingCount++;
    }

    totalChatsPerDay[dayKey] = (totalChatsPerDay[dayKey] || 0) + 1;
    totalChatsPerWeek[`${year}-${weekOfYear}`] = (totalChatsPerWeek[`${year}-${weekOfYear}`] || 0) + 1;
    totalChatsPerMonth[dayKey.substring(0, 7)] = (totalChatsPerMonth[dayKey.substring(0, 7)] || 0) + 1;

    if (!weeklyChats[year]) {
      weeklyChats[year] = {};
    }
    if (!weeklyChats[year][month]) {
      weeklyChats[year][month] = Array(5).fill(0); // Assuming maximum 5 weeks per month
    }
    weeklyChats[year][month][weekOfMonth - 1] += 1;

    if (chat.feedback) {
      feedbacks.push({
        message: chat.feedback,
        user_id: chat.user_id,
        rating: chat.rating,
        order_id: chat.order_id,
        tracking_id: chat.tracking_id,
      });
    }
  });

  const totalDays = Object.keys(totalChatsPerDay).length;
  const totalWeeks = Object.keys(totalChatsPerWeek).length;
  const totalMonths = Object.keys(totalChatsPerMonth).length;

  const averageRating = ratingCount > 0 ? totalRatings / ratingCount : 0;

  setStats({
    totalChats: filteredChats.length,
    averageChatsPerDay: totalDays ? filteredChats.length / totalDays : 0,
    averageChatsPerWeek: totalWeeks ? filteredChats.length / totalWeeks : 0,
    averageChatsPerMonth: totalMonths ? filteredChats.length / totalMonths : 0,
    averageChatsPerYear: filteredChats.length / (totalMonths / 12),
    totalChatsPerDay,
    totalChatsPerWeek,
    totalChatsPerMonth,
    averageChatLength: filteredChats.length ? totalLength / filteredChats.length : 0,
    feedbacks,
    averageRating,
    totalRatings: ratingCount,
    weeklyChats,
  });
};
