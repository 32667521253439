import React from 'react'
import SelectProduct from './SelectProduct'
import { useChat } from '../Context/ChatProvider';


const Popup = () => {

  const {activeproduct,setProduct,setisPopped,setanimate,changeLang,lang} = useChat()
  const contents = [<SelectProduct activeproduct={activeproduct} setProduct={setProduct} handleClick={handleClick} />];

  function handleClick() {
    setisPopped(false);
    setanimate(true);
    changeLang(lang);
  }



  return (
    <div className='overlay h-screen w-screen absolute bg-overlay flex justify-center items-center  '>
      <div className='Modal relative shadow-2xl h-max w-max sm:mx-0 mx-8 bg-white rounded-[30px]  flex flex-col justify-between p-2'>
        <div className='flex flex-col gap-2 m-2 mb-0 '>{contents[0]}</div>
      </div>
    </div>
  )
}

export default Popup