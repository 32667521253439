import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="sticky top-0 left-0 right-0 p-2 px-8 flex justify-between items-center border-b-[1px] glass-navbar shadow-lg shadow-gray-50">
      <h2 className="text-xl font-semibold text-red-700">Phteven AI</h2>
      <div className="flex gap-4  items-center justify-center">
        <NavLink
          to="/admin/dashboard"
          className={({ isActive }) =>
            isActive ? "active-link" : "inactive-link"
          }
        >
          Dashboard
        </NavLink>
        <NavLink
          to="/admin/chathistory"
          className={({ isActive }) =>
            isActive ? "active-link" : "inactive-link"
          }
        >
          Chats
        </NavLink>
      </div>
    </nav>
  );
};

export default Navbar;
