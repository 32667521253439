import React, { useState, useRef, useEffect } from "react";
import send from "../Assets/send.svg";
import {
  postshipChat,
  getorder,
  trackorder,
  loginNew,
} from "../Routes/chatRoutes";
import { useChat } from "../Context/ChatProvider";
import Linkify from "react-linkify";
import Logoutpopup from "./Logoutpopup";
import Emailpopup from "./Emailpopup";
import Orderpopup from "./Orderpopup";
import { formatTimestamp } from "../Utils/Misc";
const Chatbot = () => {
  const {
    allmessages,
    emailPopup,
    ratingPopup,
    setemailPopup,
    setratingPopup,
    user_id,
  } = useChat();
  const orderData = useRef([]);
  const state = useRef("waiting_for_orderid");
  const order_id = useRef();
  const zipcode = useRef();
  const tracking_code = useRef(null);
  const msg_for_chatbot = useRef();
  const carrier = useRef(null);
  const con_len = useRef(1);
  const [inputValue, setInputValue] = useState("");
  const [isposting, setisposting] = useState(false);
  const [shipmessages, setshipmessages] = useState(allmessages.current["ship"]);
  const [orderdetails, setorderdetails] = useState();
  const [feedback, setfeedback] = useState(false);
  const [orderpopup, setorderpopup] = useState(false);
  const [orderpopupbtn, setorderpopupbtn] = useState(false);
  const [popupOpened, setpopupOpened] = useState(false);
  const messagesContainerRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);
  const able_to_contact = useRef(false);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleOrderidTracking = async (order_id, zipcode, messages) => {
    const response = await getorder(
      user_id.current,
      order_id,
      orderData,
      tracking_code,
      carrier,
      zipcode,
      messages
    );
    if (response == -1) {
      pushmessage(
        "The entered  zipcode doesn't match the order's zipcode.\nPlease enter your orderId again.",
        "assistant",
        "ship"
      );
      state.current = "waiting_for_orderid";
    } else if (response == -2) {
      pushmessage(
        "OrderId doesnt exist .\nPlease enter a valid orderId.",
        "assistant",
        "ship"
      );
      state.current = "waiting_for_orderid";
    } else {
      trackorder(
        user_id.current,
        tracking_code,
        order_id,
        carrier,
        setorderdetails,
        orderData,
        msg_for_chatbot,
        pushmessage,
        state
      );
      setorderpopupbtn(true);
    }
    setShouldScroll(true);
  };

  const TalkToBot = () => {
    pushmessage(inputValue, "user", "ship_msgs_for_bot");
    const params = {
      user_id: user_id.current,
      content: allmessages.current["ship_msgs_for_bot"],
      contentfordb: allmessages.current["ship_msgs_for_db"],
    };

    postshipChat(params, con_len, setisposting, allmessages, setshipmessages)
      .then(() => {
        setShouldScroll(true);
      })
      .catch((error) => {
        console.error(error);
      });
    setShouldScroll(true);
  };

  const pushmessage = (message, role, key) => {
    let newMessage = {
      id: ++con_len.current,
      content: message,
      role: role,
      Timestamp: new Date(),
    };
    allmessages.current[key].push(newMessage);
    if (key === "ship") {
      setshipmessages(allmessages.current["ship"]);
      if (typeof message != "object")
        allmessages.current["ship_msgs_for_db"].push(newMessage);
      else
        allmessages.current["ship_msgs_for_db"].push({
          id: ++con_len.current,
          content: "<Tracking Details>",
          role: role,
          Timestamp: new Date(),
        });
    }
    if (role === "assistant") setisposting(false);
  };

  const extractNumbers = (input) => {
    const regex = /(\b\d{8}\b|\b\d{13}\b|\d+-?\d+-?\d+)/g;
    const numbers = input.match(regex);

    if (numbers) {
      const filteredNumbers = numbers
        .map((number) => {
          const cleanedNumber = number.replace(/[-]/g, ""); // Remove hyphens from the number
          if (cleanedNumber.length === 8 || cleanedNumber.length === 13)
            return cleanedNumber;
          else return null;
        })
        .filter((number) => number !== null);
      return filteredNumbers.length === 0 ? 0 : filteredNumbers[0];
    } else {
      return 0;
    }
  };

  const handleSubmit = async (e) => {
    setInputValue(" ");
    setfeedback(true);
    setisposting(true);
    e.preventDefault();
    if (inputValue.trim() === "") return;
    pushmessage(inputValue, "user", "ship");

    if (state.current === "talk_to_bot" && extractNumbers(inputValue)) {
      state.current = "waiting_for_orderid";
    }

    if (state.current === "waiting_for_orderid") {
      order_id.current = extractNumbers(inputValue);
      if (order_id.current) {
        state.current = "waiting_for_zipcode";
        pushmessage(
          "Please enter the zipcode associated with the order(billing or shipping)",
          "assistant",
          "ship"
        );
      } else
        pushmessage(
          "Please enter the order ID in numbers. It starts with 18XXXXXX.",
          "assistant",
          "ship"
        );

      setShouldScroll(true);
      return;
    }

    if (state.current === "waiting_for_zipcode") {
      zipcode.current = inputValue.trim();
      state.current = "get_order";
    }

    if (state.current === "get_order") {
      handleOrderidTracking(
        order_id.current,
        zipcode.current,
        allmessages.current["ship_msgs_for_db"]
      );
      setShouldScroll(true);
      return;
    }

    if (state.current === "talk_to_bot") {
      TalkToBot();
      able_to_contact.current = true;
      setShouldScroll(true);
    }
  };

  const handleLogout = () => {
    setratingPopup(true);
    setpopupOpened(true);
  };

  const handleEmail = () => {
    setemailPopup(true);
    setpopupOpened(true);
  };

  const handleOrderpopup = () => {
    setorderpopup(true);
    setpopupOpened(true);
  };

  useEffect(() => {
    scrollToBottom();
  }, [shipmessages]);

  useEffect(() => {
    if (shouldScroll) {
      scrollToBottom();
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  useEffect(() => {
    const fetchLogin = async () => {
      await loginNew(user_id);
    };

    fetchLogin();
  }, []);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  return (
    <div className="flex justify-center items-center bg-transparent h-screen flex-col  overflow-hidden sm:m-0  ">
      {/* <Navbar animate={animate} logonumber={2} /> */}
      {ratingPopup && <Logoutpopup user_id={user_id} activeproduct={"ship"} setpopupOpened={setpopupOpened} />}
      {emailPopup && able_to_contact.current && (
        <Emailpopup
          user_id={user_id}
          activeproduct={"ship_msgs_for_db"}
          allmessages={allmessages}
          setemailPopup={setemailPopup}
          setpopupOpened={setpopupOpened}
        />
      )}
      {orderpopup && (
        <Orderpopup
          state={state}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setorderpopup={setorderpopup}
          handleSubmit={handleSubmit}
          setpopupOpened={setpopupOpened}
        />
      )}
      <div
        className={` animate-fade-in relative bg-gray-50 h-full flex  flex-col  p-4  w-full  rounded-[2rem] justify-end  overflow-hidden }`}
      >
        <div
          className="flex flex-col space-y-2 overflow-y-hidden container1 "
          ref={messagesContainerRef}
        >
          {shipmessages.map((message) => (
            <div
              key={message.id}
              className={`scroll-smooth  w-full font-main font-semibold flex flex-col pb-10 ${
                message.role === "assistant"
                  ? "flex items-start"
                  : "flex items-end"
              }`}
            >
              <div
                className={`max-w-[18rem] whitespace-pre-line ${
                  message.role === "assistant"
                    ? "bg-white text-black rounded-lg p-2 px-4 msg-ass border-[1px] border-black shadow-md shadow-gray-200"
                    : "bg-black text-white rounded-lg p-2 px-4 msg-user shadow-md shadow-gray-200"
                }`}
              >
                <Linkify properties={{ style: { color: "#FFFFFF" } }}>
                  {message.content}
                </Linkify>
              </div>
              <span className=" font-semibold text-[0.7rem] italic text-gray-600 mt-2">
                {formatTimestamp(message.Timestamp)}
              </span>
            </div>
          ))}
          {isposting && (
            <span
              className={`animate-fade-in text-[0.5rem] font-bold font-main italic text-gray-300 ${
                feedback && "mb-4"
              }`}
            >
              Phteven is typing
              <span className="animate-ping text-black"> ...</span>
            </span>
          )}
          {feedback &&
            !isposting &&
            !popupOpened &&(
              <span className="btm-btn-container fixed flex justify-center gap-4  w-full bottom-20 left-1/2 transform -translate-x-1/2 z-20 ">
                {able_to_contact.current && (
                  <button
                    className="border-2 border-black px-2 py-1 rounded-2xl font-main text-sm font-semibold bg-white"
                    onClick={handleEmail}
                  >
                    I Want a Real Human
                  </button>
                )}
                {orderpopupbtn &&
                  !popupOpened &&(
                    <button
                      className="border-2 border-red-700 px-2 py-1 rounded-2xl font-main text-sm font-semibold bg-white"
                      onClick={handleOrderpopup}
                    >
                      Track Another Order
                    </button>
                  )}
                {!popupOpened && (
                  <button
                    className="border-2 border-black px-2 py-1 rounded-2xl font-main text-sm font-semibold bg-white"
                    onClick={handleLogout}
                  >
                    End Conversation
                  </button>
                )}
              </span>
            )}
        </div>
        <form onSubmit={handleSubmit} className=" items-center ">
          <div className=" mt-4  flex gap-2 justify-between items-center border-black border-[1px] rounded-[20px] input-shadow pr-2 ">
            <input
              type="text"
              placeholder="Type your message..."
              className=" px-4 h-12 w-[90%] text-black rounded-[20px] bg-gray-50"
              value={inputValue}
              onChange={handleInputChange}
            />
            <button type="submit" className="flex justify-center items-center">
              <img
                src={send}
                className="h-10 w-10 send-shadow hover:scale-110 "
                alt="Send"
              ></img>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Chatbot;
