import axios from "axios";
import { formatOrderDetails } from "../Utils/Misc";

export function loginExisting(user_id_new) {
  const params = { user_id: user_id_new };
  axios
    .post(process.env.REACT_APP_API + "/login", params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "/",
      },
    })
    .then((response) => {})
    .catch((err) => {
      console.log(err);
    });
}

export function loginNew(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API + "/login", {
        headers: {
          "Content-Type": "application/json",
          Accept: "/",
        },
      })
      .then((response) => {
        user_id.current = response.data;
        resolve(user_id.current);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function postChat(
  params,
  con_len,
  setMessages,
  setisposting,
  activeproduct,
  allmessages
) {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API + "/chat", params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "/",
        },
        responseType: "text",
      })
      .then((response) => {
        if (
          response.status === 201 &&
          response.data === "Message length Exceeded"
        ) {
          resolve(-1);
        } else {
          const assistant_message = response.data;
          con_len.current += 1;
          const botreply = {
            id: con_len.current,
            content: assistant_message,
            role: "assistant",
            Timestamp: new Date(),
          };
          allmessages.current[activeproduct].push(botreply);
          setMessages(allmessages.current[activeproduct]);
          setisposting(false);
          resolve(0);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(1);
      });
  });
}

export function postshipChat(
  params,
  con_len,
  setisposting,
  allmessages,
  setshipmessages
) {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API + "/shipchat", params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "/",
        },
        responseType: "text",
      })
      .then((response) => {
        if (
          response.status === 201 &&
          response.data === "Message length Exceeded"
        ) {
          resolve(-1);
        } else {
          const assistant_message = response.data;
          con_len.current += 1;
          const botreply = {
            id: con_len.current,
            content: assistant_message,
            role: "assistant",
            Timestamp: new Date(),
          };
          allmessages.current["ship"].push(botreply);
          allmessages.current["ship_msgs_for_bot"].push(botreply);
          setshipmessages(allmessages.current["ship"]);
          setisposting(false);
          resolve(0);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(1);
      });
  });
}

export function setRating(user_id_this, rating, feedback, bot) {
  const data = {
    user_id: user_id_this.current,
    bot: bot,
    rating: rating,
    feedback: feedback,
  };

  axios
    .post(process.env.REACT_APP_API + "/setRating", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "/",
      },
    })
    .catch((err) => {
      console.log(err);
    });
}

export function setRatingTest(user_id_this, rating, bot) {
  const data = {
    user_id: user_id_this.current,
    bot: bot,
    rating: rating,
  };

  axios
    .post(process.env.REACT_APP_API + "/setRatingTest", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "/",
      },
    })
    .catch((err) => {
      console.log(err);
    });
}

export function editResponse(user_id_this, original, edit, bot, e) {
  const data = {
    user_id: user_id_this.current,
    bot: bot,
    edit: edit,
    original: original,
  };

  axios
    .post(process.env.REACT_APP_API + "/editResponse", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "/",
      },
    })
    .then((response) => {
      alert(response.data);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getorder(
  user_id,
  order_id,
  orderData,
  tracking_code,
  carrier,
  zipcode,
  messages
) {
  const data = {
    user_id: user_id,
    order_id: order_id,
    zipcode: zipcode,
    messages: messages,
  };

  try {
    const response = await axios.post(
      process.env.REACT_APP_API + "/getorder",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "/",
        },
        responseType: "text",
      }
    );

    if (response.status != 200) {
      if (response.data === 'zipcode_mismatch')
       return -1;
      else
       return -2
    }

    const order_info = JSON.parse(response.data);
    orderData.current = order_info;
    if (orderData.current?.fulfillments.length < 1) {
      tracking_code.current = null
      carrier.current = null
      return 1;
    }

    if (orderData.current?.fulfillments.length >= 1 && orderData.current?.fulfillments[0]?.tracking_number ) {
      tracking_code.current =orderData.current?.fulfillments[0]?.tracking_number;
      carrier.current = orderData.current?.fulfillments[0]?.tracking_company;
      return 1;
    } else {
      tracking_code.current = 'not_shipped'
      carrier.current = 'not_shipped'
      return 1;
    }

  } catch (error) {
    console.log(error);
    return -1;
  }
}

export function trackorder(
  user_id,
  tracking_code,
  order_id,
  carrier,
  setOrderDetails,
  orderData,
  msg_for_chatbot,
  pushmessage,
  state
) {

  if (!tracking_code.current || tracking_code.current == 'not_shipped') {
    const orderDetails = orderData.current;
      state.current = "talk_to_bot";
      pushmessage(
        formatOrderDetails(
          orderDetails,
          tracking_code.current,
          msg_for_chatbot,
          carrier
        ),
        "assistant",
        "ship"
      );
      pushmessage(msg_for_chatbot.current, "user", "ship_msgs_for_bot");
      setOrderDetails(orderData.current);
    return;
  }

  const data = {
    user_id: user_id,
    tracking_code: tracking_code.current,
    order_id: order_id.current,
    carrier: carrier.current,
  };
  axios
    .post(process.env.REACT_APP_API + "/trackorder", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "/",
      },
    })
    .then((response) => {
      let tracking_details = null;

      //Attribute names are different for each carrier , hence the different assignments
      if (carrier.current === "USPS")
        tracking_details = response?.data?.tracking_details;
      else if (carrier.current === "DHL")
        tracking_details = response?.data?.events;
      else if (carrier.current === "UPS")
        tracking_details = response?.data?.activity;

      const orderDetails = orderData.current;
      state.current = "talk_to_bot";
      pushmessage(
        formatOrderDetails(
          orderDetails,
          tracking_details,
          msg_for_chatbot,
          carrier
        ),
        "assistant",
        "ship"
      );
      pushmessage(msg_for_chatbot.current, "user", "ship_msgs_for_bot");
      setOrderDetails(orderData.current);
    })
    .catch((err) => {
      console.log(err);
      pushmessage("Order Id cannot be tracked", "assistant", "ship");
      setOrderDetails(null);
    });
}


export function setmodel(model) {
  const data = {
    model: model,
  };

  axios
    .post(process.env.REACT_APP_API + "/setmodel", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "/",
      },
    })
    .then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getmodel(setgptmodel) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API}/getmodel`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "/", // Modify this according to the expected response type
        },
      })
      .then((response) => {
        setgptmodel(response.data.gpt_model);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
}

export function fetchchat(user_id, bot) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API}/${user_id}/${bot}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*", // Modify this according to the expected response type
        },
      })
      .then((response) => {
        if (response.data != null) {
          console.log(response.data);
          resolve(response); // Resolve the promise with the response data
        } else {
          reject(new Error("No data returned from the API"));
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
}

export function sendemail(user_id_this, email_id, current_bot, conversations) {
  const data = {
    user_id: user_id_this.current,
    email_id: email_id,
    current_bot: current_bot,
    conversations: conversations,
  };

  axios
    .post(process.env.REACT_APP_API + "/sendemail", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "/",
      },
    })
    .then((response) => {
      console.log(response.data);
    })
    .catch((err) => {
      console.log(err);
    });
}
