
 export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

 export const getLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
}

export const storeMessage = (product, message, user_id, lang, gpt_model) => {
    setLocalStorage("chats", message);
  };
