import React, { useState } from "react";
import Shipping from "./Shipping";
import Navbar from "./Navbar";

const ShippingMain = () => {
  const [ChatWindow, setChatWindow] = useState(false);
  function handleChatWindow() {
    setChatWindow((prevState) => !prevState);
  }

  return (
    <div className="bg-black h-screen w-screen">
      {!ChatWindow && (
        <button
          onClick={handleChatWindow}
          className="absolute right-8 bottom-8 bg-white rounded-full w-12 h-12 font-bold"
        >
          Chat
        </button>
      )}
      {ChatWindow && (
        <div className="absolute right-8 bottom-8 rounded-xl flex flex-col items-center">
          <div className=" w-[30rem] flex gap-4 justify-center items-center">
          <Navbar  logonumber={2} />
          <button
            onClick={handleChatWindow}
            className=" text-2xl font-bold w-10 h-10 rounded-full bg-white "
          >
            X
          </button>
          </div>
          <embed src="/embed/shipping" className="w-full h-[45rem] shadow-xl shadow-[#000000] rounded-[2rem]"/>
        </div>
      )}
    </div>
  );
};

export default ShippingMain;
