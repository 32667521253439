import Cookies from "js-cookie";
import { loginNew } from "../Routes/chatRoutes";

export async function getId(setactiveproduct, user_id) {
  const minutes = process.env.REACT_APP_SESSION_EXPIRATION;
  const days = minutes / (24 * 60);

    const generatedId = await loginNew(user_id);
    user_id.current = generatedId;
    Cookies.set("user_id", generatedId, { expires: days });
    setactiveproduct("LA");

}
