import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

const ProtectedLayout = () => {
  const authToken = localStorage.getItem("auth_token");

  if (!authToken) {
    return <Navigate to="/admin/login" replace />;
  }
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

export default ProtectedLayout;
