import axios from "axios";

export default function getAuth(credentials) { 
  return axios 
    .post(process.env.REACT_APP_API + "/admin/login", credentials, {
      headers: {
        "Content-Type": "application/json",
        Accept: "/",
      },
    })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((err) => {
      throw err; 
    });
}
