import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChatProvider } from "./Context/ChatProvider";
import { Toaster } from "./Components/ui/toaster"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <ChatProvider>
     <App />
     <Toaster />
   </ChatProvider>
);

reportWebVitals();
