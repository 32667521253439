import React from 'react'
import logo from '../Assets/logo.png'
import logo2 from '../Assets/logo2.png'

const Navbar = ({animate,logonumber = 1 }) => {
  return (
    <div className={`flex justify-center items-center  ${animate?'animate-fade-in-navbar':''}`}>
        <img src={logonumber === 1 ? logo : logo2} alt='MANTIS' className='h-12 w-50 my-2'/>
        {logonumber === 2 && (
          <h2 className=" font-tactic ml-2 text-white  text-2xl ">
          TRACK MANTIS SHIPMENT
        </h2>
        )}
    </div>
  )
}

export default Navbar