import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchchat } from "../Routes/chatRoutes";

const DispChat = () => {
  const { bot, user_id } = useParams();
  const [chat, setchat] = useState();
  const [popup, setpopup] = useState(false);

  useEffect(() => {
    async function fetch() {
      const results = await fetchchat(user_id, bot);
      console.log(results);
      if (results.status != 201) {
        setpopup(false);
        setchat(results.data);
      } else setpopup(true);
    }
    fetch();
  }, []);

  return (
    <>
      {popup ? (
        <div className="h-screen w-screen text-xl text-red-700  flex justify-center items-center text-center font-main">
          Oops! <br></br>The Requested Chat Does Not Exist
        </div>
      ) : (
        <div className="bg-slate-100  font-main overflow-x-hidden h-screen w-screen flex justify-center">
          <div className="bg-white rounded-3xl shadow p-4 sm:w-[70%] w-full h-max m-4">
            <div className="flex  justify-between">
              <div className="flex flex-col gap-1">
                <div>
                  <span className="attr-head">User Id </span>
                  <span className="attr-value">{chat?.user_id}</span>
                </div>

                <div>
                  <span className="attr-head">Language </span>
                  <span className="attr-value">
                    {chat?.language}
                  </span>
                </div>

                <div>
                  <span className="attr-head">Start Time </span>
                  <span className="attr-value">
                    {chat?.start_time}
                  </span>
                </div>

                <div>
                  <span className="attr-head">Feedback </span>
                  <span className="attr-value">
                    {chat?.feedback ? chat.feedback : "No Feedback"}
                  </span>
                </div>

                <div>
                  <span className="attr-head">ChatGpt Model</span>
                  <span className="attr-value">
                    {chat?.gpt_model}
                  </span>
                </div>

                <div>
                  <span className="attr-head">User Action </span>
                  <span className="attr-value">
                    {chat?.action}
                  </span>
                </div>

                <div>
                  <span className="attr-head">User Email </span>
                  <span className="attr-value">
                    {chat?.useremail? chat.useremail : "None"}
                  </span>
                </div>
              </div>

              <div className="flex flex-col items-end gap-2">
                <div>
                  {chat?.rating}{" "}
                  <span className="text-red-700">
                    {chat?.rating ? "★" : "Not Rated"}
                  </span>
                </div>

                <div className="border-red-700 text-red-700 border-[1px] rounded-full w-8 h-8 flex justify-center items-center text-sm font-bold">
                  {chat?.bot}
                </div>
              </div>
            </div>

            <div className="text-lg font-semibold mt-2 mb-2 flex gap-2 text-red-700  justify-center items-center">
              <span>Chat Messages </span>
              <div className="mr-2  text-xs rounded-full p-2 text-red-700  border-[1px] border-red-700 w-4 h-4 flex justify-center items-center">
                {chat?.length - 1}{" "}
              </div>
            </div>
            <div className="chatcontainer bg-gray-200 rounded-2xl py-2 px-4">
              {chat?.chat.map((msg, index) => (
                <div className="flex justify-between items-center " key={index}>
                  <span className=" mb-2">
                    <span className={`capitalize   rounded-xl font-bold ${msg.role=='user'?'text-blue-800':' text-red-700'}`}>
                      {msg.role} :{" "}
                    </span>
                    <span className="text-sm text-gray-800 font-semibold">{msg?.content}</span>
                  </span>
                  <span className="text-xs text-gray-400">{msg?.time}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DispChat;
