import moment from "moment";

export function formatTimestamp(timestamp) {
  const date = new Date(timestamp);

  if (isNaN(date)) {
    return "";
  }

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

function FormattedDateTime({ dateTimeString }) {
  const formattedDate = moment(dateTimeString).format("MMMM D, YYYY h:mm A");
  return formattedDate;
}

function formatDateTimeUps(dateString, timeString) {
  const date = new Date(
    `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(
      6,
      8
    )}T${timeString.slice(0, 2)}:${timeString.slice(2, 4)}:${timeString.slice(
      4,
      6
    )}`
  );

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // handle midnight

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

const dateString = "20220126";
const timeString = "163000";
const formattedDateTime = formatDateTime(dateString, timeString);
console.log(formattedDateTime); // Output: "04:30 PM"

export function formatDatestamp(date) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = date.toLocaleDateString(undefined, options);
  return formattedDate;
}

export function formatDateTime(timestamp) {
  const date = new Date(timestamp);

  if (isNaN(date)) {
    return "";
  }
  return `${formatDatestamp(date)} ${formatTimestamp(date)}`;
}

export const copyToClipboard = (text, setShowPopup) => {
  setShowPopup(true);
  setTimeout(() => {
    setShowPopup(false);
  }, 2000);
  const textField = document.createElement("textarea");
  textField.value = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

export function formatOrderDetails(
  orderDetails,
  tracking_details,
  msg_for_chatbot,
  carrier
) {
  if (!orderDetails) {
    return "Order details not available.";
  }

  const orderNumber = orderDetails.name || "N/A";
  const returnLabelCount = orderDetails.line_items.filter(
    (item) => item.name === "Return Label (sent via email)"
  ).length;
  const productName = orderDetails.line_items
    .filter((item) => item.name !== "Return Label (sent via email)")
    .map((item) => item.name || "N/A")
    .join(", ");
  const isMultipleProducts =
    orderDetails.line_items.filter(
      (item) => item.name !== "Return Label (sent via email)"
    ).length > 1;
    const startMessage = () => {
      return (
        <>
          Shipment Status :
          <br />
          <span className="text-red-600 capitalize ">
            {`${orderDetails?.fulfillments[0]?.shipment_status} ( ${moment(
              orderDetails?.fulfillments[0]?.updated_at
            ).format("MMM DD, YYYY")} )`}
          </span>
          {returnLabelCount > 0 && (
            <>
              <br />
              <span className="text-red-600  ">
                Returned Items : {returnLabelCount}
              </span>
            </>
          )}
          <br />
          <br />
        </>
      );
    };
  const price =
    orderDetails.presentment_currency + " " + orderDetails.current_total_price;
  const shippingMethod =
    orderDetails.shipping_lines[0]?.title || "Not Assigned";
  const orderDate = orderDetails.created_at || "N/A";
  const shippingAddress = orderDetails.shipping_address
    ? `${orderDetails.shipping_address.name}, ${orderDetails.shipping_address.address1}, ${orderDetails.shipping_address.city}, ${orderDetails.shipping_address.province}, ${orderDetails.shipping_address.zip}, ${orderDetails.shipping_address.country}`
    : "N/A";

  if (tracking_details === null || tracking_details == "not_shipped") {
    msg_for_chatbot.current = `Order ID: ${orderNumber},Product Name : ${productName},Email: ${
      orderDetails.contact_email
    },orderDate : ${orderDate},Total Price: ${price},Financial Status: ${
      orderDetails.financial_status
    },Referring Site: ${orderDetails.referring_site},Customer Name: ${
      orderDetails.customer.first_name
    } ${orderDetails.customer.last_name},Customer Email: ${
      orderDetails.customer.email
    },Customer Phone: ${
      orderDetails.customer.phone
    },Shipping Address: ${shippingAddress},Payment Gateway: ${orderDetails.payment_gateway_names.join(
      ", "
    )},Tax Exempt: ${orderDetails.tax_exempt ? "Yes" : "No"}`;

    const styled = (label, value) => {
      return (
        <div>
          <span className="font-semibold text-red-600 mr-8">{label}</span>
          <br />
          <span className="font-medium">
            {label === "Order Date" ? FormattedDateTime(value) : value}
          </span>
        </div>
      );
    };

    
    return (
      <div>
        {startMessage()}
        Your order with ID : {orderNumber} <br />
        with the product{isMultipleProducts ? "s" : ""}{" "}
        <span className="text-red-600">{productName}</span> <br />
        {tracking_details
          ? "has been accepted and we are getting it ready. "
          : "has been confirmed and is awaiting fulfillment."}
        <br />
        Track your order status{" "}
        <a target="_blank" href={orderDetails.order_status_url}>
          here
        </a>
        <br />
        <br />
        <div className="flex flex-col gap-2">
          {styled("Price", price)}
          {styled("Shipping method", shippingMethod)}
          {styled("Shipping Address", shippingAddress)}
          {styled("Order Date", orderDate)}
        </div>
        <br />
        If you have any additional queries, please post it here.
      </div>
    );
  }

  const trackingNumber = orderDetails.fulfillments[0]?.tracking_number || "N/A";
  const trackingUrl = orderDetails.fulfillments[0]?.tracking_url || "N/A";
  const fulfillmentDate = orderDetails.fulfillments[0]?.created_at || "N/A";
  const shippingCompany =
    orderDetails.fulfillments[0]?.tracking_company || "N/A";
  msg_for_chatbot.current = `Order ID: ${orderNumber},Product Name : ${productName},Shipping Company : ${shippingCompany},Shipping Method : ${shippingMethod},Confirmation Number: ${
    orderDetails.confirmation_number
  },Email: ${
    orderDetails.contact_email
  },orderDate : ${orderDate},fulfillmentDate : ${fulfillmentDate},Total Price: ${price},Financial Status: ${
    orderDetails.financial_status
  },Order Status URL: ${orderDetails.order_status_url},Referring Site: ${
    orderDetails.referring_site
  },Customer Name: ${orderDetails.customer.first_name} ${
    orderDetails.customer.last_name
  },Customer Email: ${orderDetails.customer.email},Customer Phone: ${
    orderDetails.customer.phone
  },Shipping Address: ${shippingAddress},Payment Gateway: ${orderDetails.payment_gateway_names.join(
    ", "
  )},Tax Exempt: ${
    orderDetails.tax_exempt ? "Yes" : "No"
  },Tracking Number : ${trackingNumber}`;

  let filteredTrackingDetails = [];
  if (carrier.current === "USPS") {
    tracking_details.forEach((track, index) => {
      msg_for_chatbot.current += `(${index + 1}) Status : ${track?.status}, ${
        track?.message
      }, Reached ${track?.tracking_location?.city}, ${
        track?.tracking_location?.state
      }, ${track?.tracking_location?.country}, Zip : ${
        track?.tracking_location?.zip
      }, at time : ${formatTimestamp(track?.datetime) || "N/A"}`;
      const message = track?.message.toLowerCase();
      if (!message.includes("in transit") && !message.includes("departed"))
        filteredTrackingDetails.push({
          message: track?.message,
          location: `${track?.tracking_location?.city.toLowerCase()}${
            track?.tracking_location?.state
              ? `, ${track?.tracking_location?.state.toLowerCase()}`
              : ""
          }`,
          timestamp: formatTimestamp(track?.datetime),
        });
    });
  } else if (carrier.current === "DHL") {
    tracking_details.forEach((track, index) => {
      msg_for_chatbot.current += `(${index + 1}) Description : ${
        track?.description
      } ,Reached ${
        track?.location?.address?.addressLocality
      }, at time : ${formatTimestamp(track?.timestamp)}`;
      const message = track?.description.toLowerCase();
      if (!message.includes("in transit") && !message.includes("departed"))
        filteredTrackingDetails.push({
          message: track?.description,
          location: track?.location?.address?.adressLocality,
          timestamp: formatTimestamp(track?.timestamp),
        });
    });
  } else if (carrier.current === "UPS") {
    tracking_details.forEach((track, index) => {
      msg_for_chatbot.current += `(${index + 1}) Description : ${
        track?.status?.description
      } ,Reached ${track?.location?.address?.city} Country : ${
        track?.location?.address?.country
      }, at time : ${formatDateTimeUps(track?.date, track?.time)}`;
      const message = track?.status?.description.toLowerCase();
      if (!message.includes("in transit") && !message.includes("departed"))
        filteredTrackingDetails.push({
          message: track?.status?.description,
          location: track?.location?.address?.city
            ? `${track?.location?.address?.city} , ${track?.location?.address?.country}`
            : track?.location?.address?.country,
          timestamp: formatDateTimeUps(track?.date, track?.time),
        });
    });
  }
  return (
    <div>
      {startMessage()}
      Your order with ID : {orderNumber} <br />
      with the product{isMultipleProducts ? "s" : ""}{" "}
      <span className="text-red-600">{productName}</span> <br />
      has been successfully shipped via {shippingCompany} using {shippingMethod}
      .
      <br />
      <br />
      Your shipment {trackingNumber} can be tracked{" "}
      <a href={trackingUrl} target="_blank">
        here
      </a>
      .
      <br />
      <br />
      <div className="border-1 border-black border-solid">
        {filteredTrackingDetails.map((track, index) => (
          <div className=" relative " key={index}>
            <div className="w-4 h-4 rounded-full bg-red-700 absolute -left-[0.45rem] mt-1 z-10 "></div>
            {index < filteredTrackingDetails.length - 1 ? (
              <div className="w-[0.15rem] h-full bg-black absolute -left-[0.05rem] mt-1"></div>
            ) : (
              <div className="w-[0.15rem] h-full bg-black absolute -left-[0.05rem] mt-1"></div>
            )}
            <div className="border-none px-4 pb-4">
              <div>{track?.message}</div>
              <div className="text-xs font-normal capitalize">
                {track?.location}
              </div>
              <div className="text-xs font-semibold">{track?.timestamp}</div>
            </div>
          </div>
        ))}
      </div>
      <br />
      If you have any additional queries, please post it here.
    </div>
  );
}
