import React, { useEffect, useState } from "react";
import fetchdata from "../../Routes/Admin/FetchData";
import moment from "moment";
import { DatePickerWithRange } from "../ui/DatePicker";
import { calculateStats } from "./CalculateChatStats";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";

import { Separator } from "../ui/separator";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import loading from "../ui/assets/loading.svg";

const AdminDashboard = ({ results, setresults }) => {
  const [selectedBot, setSelectedBot] = useState("SHIP");
  const [selectedAverage, setSelectedAverage] = useState("day");
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectedMonth] = useState(moment().format("MMM"));
  const [dateRange, setDateRange] = useState({
    from: moment().startOf("day").toDate(),
    to: moment().endOf("day").toDate(),
  });
  const [stats, setStats] = useState({
    totalChats: 0,
    averageChatsPerDay: 0,
    averageChatsPerWeek: 0,
    averageChatsPerMonth: 0,
    averageChatsPerYear: 0,
    totalChatsPerDay: {},
    totalChatsPerWeek: {},
    totalChatsPerMonth: {},
    averageChatLength: 0,
    feedbacks: [],
    averageRating: 0,
    totalRatings: 0,
  });

  useEffect(() => {
    const authToken = localStorage.getItem("auth_token");
    if (!results) {
      fetchdata(authToken)
        .then((response) => {
          setresults(response);
          calculateStats(response.chats, setStats, selectedBot);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      calculateStats(results.chats, setStats, selectedBot);
    }
  }, [results, selectedBot]);

  const getTotalChatsForPeriod = (startDate, endDate) => {
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    return stats.totalChatsPerDay
      ? Object.entries(stats.totalChatsPerDay).reduce((acc, [date, count]) => {
          const dateMoment = moment(date);
          if (dateMoment.isBetween(startMoment, endMoment, "day", "[]")) {
            return acc + count;
          }
          return acc;
        }, 0)
      : 0;
  };


  const CustomTooltip = ({ active, payload, label }) => {
    console.log(selectedMonth)
    if (active && payload && payload.length) {
      const weekIndex = parseInt(label.split(" ")[1]) - 1; // Extract week index from label
      const month = moment(selectedMonth, 'MMM').month(); // Convert selected month to month index
      const weekStartDate = moment()
        .year(selectedYear)
        .month(month)
        .week(weekIndex + 1)
        .startOf("week")
        .format("D");
      const weekEndDate = moment()
        .year(selectedYear)
        .month(month)
        .week(weekIndex + 1)
        .endOf("week")
        .format("D");
  
      return (
        <div className="border-[1px] p-2 rounded-lg glass">
          <p className="label">{`Week ${
            weekIndex + 1
          } : ${selectedMonth} ${weekStartDate} - ${selectedMonth} ${weekEndDate}`}</p>
          <p className="intro">{`Chats : ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };
  
  

  const renderWeeklyChats = () => {
    if (!stats.weeklyChats || !stats.weeklyChats[selectedYear]) {
      return <p>No data available for {selectedYear}.</p>;
    }

    const yearData = stats.weeklyChats[selectedYear];
    const monthData = yearData[selectedMonth];
    if (!monthData) {
      return (
        <p>
          No data available for {selectedMonth} {selectedYear}.
        </p>
      );
    }

    const data = monthData.map((week, index) => ({
      week: `Week ${index + 1}`,
      chats: week,
    }));

    return (
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis
            dataKey="week"
            tick={{ fontSize: 13, fill: "black", fontWeight: "600" }}
            tickMargin={15}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{ fontSize: 13, fill: "black", fontWeight: "600" }}
            tickMargin={15}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip content={<CustomTooltip />} />
          <Line type="monotone" dataKey="chats" stroke="#dc2626" />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const getSelectedAverageValue = () => {
    switch (selectedAverage) {
      case "day":
        return stats.averageChatsPerDay.toFixed(2);
      case "week":
        return stats.averageChatsPerWeek.toFixed(2);
      case "month":
        return stats.averageChatsPerMonth.toFixed(2);
      default:
        return "0";
    }
  };

  const renderValue = (value) => {
    if (!results) {
      return (
        <div className=" flex ">
          <h2 className="font-semibold text-gray-400">Fetching</h2>
          <img alt="loading" src={loading} className="w-6 h-6 ml-2 " />
        </div>
      );
    }
    return (
      <h2 className="text-[4rem] font-bold font-value text-red-600 ">
        {value}
      </h2>
    );
  };

  return (
    <div className="p-8">
      <h2 className="text-3xl font-bold mt-4 mb-8">Analytics</h2>
      <div className="flex  items-center gap-2 ">
        <span className="border-[1px] px-2 py-1 rounded-md font-semibold">
          Bot
        </span>
        <Select
          defaultValue={selectedBot}
          onValueChange={(value) => setSelectedBot(value)}
        >
          <SelectTrigger className="w-[180px] font-semibold">
            <SelectValue placeholder="Select Bot " />
          </SelectTrigger>
          <SelectContent className="font-semibold">
            <SelectItem value="SPS">SPS</SelectItem>
            <SelectItem value="BB">BlackBeard</SelectItem>
            <SelectItem value="LA">Laser Academy</SelectItem>
            <SelectItem value="SHIP">Shipping</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <Separator className="my-8 " />
      <div className="row-1 w-full flex gap-2 justify-center mb-2 ">
        <Card className="analytics-card">
          <CardHeader>
            <CardTitle>Total Chats</CardTitle>
            <CardDescription>
              Total number of chats for {selectedBot} bot
            </CardDescription>
          </CardHeader>
          <CardContent>{renderValue(stats.totalChats)} chats</CardContent>
        </Card>
        <Card className="analytics-card">
          <CardHeader>
            <CardTitle>Average Chats</CardTitle>
            <CardDescription>
              <Select
                onValueChange={(value) => setSelectedAverage(value)}
                defaultValue={selectedAverage}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select Period" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="day">Per Day</SelectItem>
                  <SelectItem value="week">Per Week</SelectItem>
                  <SelectItem value="month">Per Month</SelectItem>
                </SelectContent>
              </Select>
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col items-start space-y-2">
              {renderValue(getSelectedAverageValue())} chats per{" "}
              {selectedAverage}
            </div>
          </CardContent>
        </Card>
        <Card className="analytics-card">
          <CardHeader>
            <CardTitle>Average Chat Length</CardTitle>
          </CardHeader>
          <CardContent>
            {renderValue(stats.averageChatLength.toFixed(1))} messages per chat
          </CardContent>
        </Card>
        <Card className="analytics-card">
          <CardHeader>
            <CardTitle>Total Chats From</CardTitle>
            <CardDescription>
              <DatePickerWithRange
                selectedRange={dateRange}
                onDateChange={setDateRange}
              />
            </CardDescription>
          </CardHeader>
          <CardContent>
            {renderValue(getTotalChatsForPeriod(dateRange?.from, dateRange?.to))}{" "}
            chats
          </CardContent>
        </Card>
      </div>
      <div className="row-2 w-full flex gap-2 justify-center mb-2 h-full">
        <Card className="analytics-card w-max h-full">
          <CardHeader>
            <CardTitle>Average Rating Per Chat</CardTitle>
            <CardDescription>
              Average rating for {selectedBot} bot
            </CardDescription>
          </CardHeader>
          <CardContent>
            {renderValue(stats.averageRating.toFixed(1))} out of{" "}
            {stats.totalRatings} ratings
          </CardContent>
        </Card>
        <Card className="analytics-card h-max transition-height">
          <CardHeader>
            <CardTitle>
              <div className="flex justify-between ">
                <h2>Weekly Chats</h2>
                <div className="flex  gap-8">
                  <Select
                    onValueChange={(value) => setSelectedYear(value)}
                    defaultValue={selectedYear.toString()}
                  >
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Select Year" />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from({ length: 5 }).map((_, i) => {
                        const year = moment().year() - i;
                        return (
                          <SelectItem key={year} value={year.toString()}>
                            {year}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <Select
                    onValueChange={(value) =>
                      setSelectedMonth(moment(value, "MMMM").format("MMM"))
                    }
                    defaultValue={moment(selectedMonth, "MMM").format("MMMM")}
                  >
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Select Month" />
                    </SelectTrigger>
                    <SelectContent>
                      {moment.months().map((month, index) => (
                        <SelectItem key={index} value={month}>
                          {month}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </CardTitle>
            <CardDescription>
              Select month and year to view weekly chat stats.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="mt-4">{renderWeeklyChats()}</div>
          </CardContent>
        </Card>
      </div>
      <div className="row-3 w-full">
        <div className="border-[1px] p-4 rounded-lg shadow-sm flex flex-col justify-start  ">
          <h2 className="font-bold text-xl mb-1 text-slate-700 leading-none tracking-tight">
            Recent feedbacks ( {stats?.feedbacks?.length} )
          </h2>
          <Table>
            <TableCaption>A list of recent feedbacks</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead>Order ID</TableHead>
                <TableHead>Tracking ID</TableHead>
                <TableHead>Message</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {stats?.feedbacks?.map((feedback, index) => (
                <TableRow key={index}>
                  <TableCell>{feedback.order_id}</TableCell>
                  <TableCell>{feedback.tracking_id || "-"}</TableCell>
                  <TableCell>{feedback.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
