import React, { useEffect, useState } from "react";

const Orderpopup = ({state, inputValue, setInputValue ,setorderpopup,handleSubmit,setpopupOpened}) => {

    const dummyPreventDefault = () => {
        // Do nothing, as it's a dummy function
      };
      
  const handleClick = () => {
  state.current = 'waiting_for_orderid';
  setorderpopup(false);
  handleSubmit({
    preventDefault: dummyPreventDefault,
  });
  setpopupOpened(false);
  }

  const handleExit = () => {
    setorderpopup(false);
    setpopupOpened(false);
    }

    useEffect(() => {
      return () => {
        setpopupOpened(false);
      }
    }, [])

  return (
    <div className="overlay h-screen w-screen absolute bg-overlay flex justify-center items-center z-10 font-main">
      <div className="Modal shadow-2xl border-[1px]  border-solid h-max w-max sm:mx-0 mx-8 bg-white rounded-[20px]  flex flex-col items-center justify-center sm:w-[20rem]  p-2">
        <div className="flex flex-col gap-2 m-2 mb-0 ">
          <div className="flex relative flex-col gap-4 justify-center items-center">
          <button className="absolute left-2 top-0"
          onClick={handleExit}
          >←</button>
            <h2>Enter your order ID</h2>
            <input
              type="decimal"
              className="border-[2px] p-2 px-4 rounded-[15px] "
              placeholder="order ID "
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            ></input>
          </div>
        </div>
        <button
          onClick={handleClick}
          className="bg-black text-white m-1 mt-4 rounded-[10px] px-4 py-1 font-bold text-sm w-max"
        >
          Track
        </button>
      </div>
    </div>
  );
};

export default Orderpopup;
