import React from "react";
import la from "../Assets/la.jpeg";
import sps from "../Assets/sps.jpeg";
import bb from "../Assets/bb.jpeg";
import { useChat } from "../Context/ChatProvider";
import Settings from "./Settings";
import settings_btn from "../Assets/settings.png";
import back from '../Assets/back.png'

const SelectProduct = ({handleClick}) => {
  const { activeproduct, setProduct, setpopup, popup,lang } = useChat();

  const Click = (product) => {
    setProduct(product);
    handleClick();
  };

  const handlePopup = () => {
    setpopup(!popup);
  };

  return (
    <div className=" flex font-main flex-col gap-2 animate-fade-in">
      <div className="flex  justify-between gap-8 sm:gap-0  ">
        <h2 className="mr-8" >
          { !popup ? "Choose Your Product" : "Choose Language "}
        </h2>
        { !popup ? (<div className="flex gap-2 bg-black text-white rounded-2xl pl-2 pr-1 py-1">
          
          <h2 className="" title="Change Language in settings">
              Language : {lang}
            </h2>
  
            <button onClick={handlePopup} className=" w-6 ">
              <img src={settings_btn} />
            </button>
  
          </div>) :
          (
            <button onClick={handlePopup} className="w-8"><img src={back} /></button>
          )}
      </div>
      <div className=" flex flex-col sm:flex-row justify-center items-start text-white  gap-8 sm:gap-4 mb-4 text-sm sm:text-md animate-fade-in-buttons sm:gap-y-4">
        {popup ? (
          <div className="  right-12 top-8 rounded-[20px] p-4 border-solid border-[1px] border-black shadow-md h-max w-80 glass ">
            <Settings />
          </div>
        ) : (
          <>
            <button
              className={`relative w-full sm:w-max ${
                activeproduct === "SPS"
                  ? "active-product2"
                  : "inactive-product2"
              }`}
              onClick={() => {
                Click("SPS");
              }}
            >
              <div className="relative aspect-ratio-1/1 flex justify-center">
                <img
                  src={sps}
                  alt="Shot Perfomance System"
                  className="object-cover h-48 w-48"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <h3 className="product-text">X2 , X3 , X10</h3>
                </div>
              </div>
            </button>

            <button
              className={`relative w-full sm:w-max  ${
                activeproduct === "LA" ? "active-product2" : "inactive-product2"
              }`}
              onClick={() => {
                Click("LA");
              }}
            >
              <div className="relative aspect-ratio-1/1 flex justify-center">
                <img
                  src={la}
                  alt="Laser Academy"
                  className="object-cover  h-48 w-48"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <h3 className="product-text">Laser Academy</h3>
                </div>
              </div>
            </button>

            <button
              className={`relative w-full sm:w-max  ${
                activeproduct === "BB" ? "active-product2" : "inactive-product2"
              }`}
              onClick={() => {
                Click("BB");
              }}
            >
              <div className="relative aspect-ratio-1/1 flex justify-center">
                <img
                  src={bb}
                  alt="BlackBeard(old)"
                  className="object-cover h-48 w-48"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <h3 className="product-text">BlackBeard</h3>
                </div>
              </div>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default SelectProduct;
