import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full bg-black h-96 p-8 flex flex-col items-center justify-between">
      <h2 className="font-semibold text-white text-4xl">Phteven AI</h2>
      <nav className="flex flex-col gap-4">
        <NavLink
          to="/admin/dashboard"
          className={({ isActive }) =>
            isActive ? "dark-active-link" : "dark-inactive-link"
          }
        >
          Dashboard
        </NavLink>
        <NavLink
          to="/admin/chathistory"
          className={({ isActive }) =>
            isActive ? "dark-active-link" : "dark-inactive-link"
          }
        >
          Chats
        </NavLink>
      </nav>
      <h2 className="  text-xs font-light text-white ">
        Copyright © 2015-2025 Mantis Tech, LLC. All rights reserved.
      </h2>
    </div>
  );
};

export default Footer;
