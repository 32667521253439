import React, { useState, useRef, useEffect } from "react";
import send from "../Assets/send.svg";
import settings_btn from "../Assets/settings.png";
import { postChat} from "../Routes/chatRoutes";
import Settings from "./Settings";
import Linkify from "react-linkify";
import { storeMessage } from "../Utils/Storage";
import AlertMessage from "./AlertMessage";
import Cookies from "js-cookie";

const Chatbot = ({
  setemailPopup,
  setratingPopup,
  messages,
  setMessages,
  user_id,
  allmessages,
  activeproduct,
  lang,
  animate,
}) => {
  const scrollRef = useRef(null);
  const con_len = useRef(1);
  const [feedback, setfeedback] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isposting, setisposting] = useState(false);
  const [popup, setpopup] = useState(false);
  const [LimitPopup, setLimitPopup] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    setisposting(true);
    e.preventDefault();
    if (inputValue.trim() === "") return;

    con_len.current += 1;
    const newMessage = {
      id: con_len.current,
      content: inputValue,
      role: "user",
      Timestamp: new Date(),
    };
    allmessages.current[activeproduct].push(newMessage);
    setMessages(allmessages.current[activeproduct]);
    setInputValue(" ");
    const params = {
      user_id: user_id.current,
      lang: lang,
      bot: activeproduct,
      content: messages,
    };

    postChat(
      params,
      con_len,
      setMessages,
      setisposting,
      activeproduct,
      allmessages,
      setLimitPopup,
      setemailPopup
    )
      .then((result) => {
        if (result === -1) {
          setLimitPopup(true);
          Cookies.set("limit_exceeded", new Date().toISOString(), { expires: 0.25 });
        } 
      })
      .catch((error) => {
        console.error(error); 
      });

    scrollToBottom();
    setfeedback(true);
    storeMessage(activeproduct, allmessages.current, user_id, lang, 3.5);
  };

  function scrollToBottom() {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }

  const handlePopup = () => {
    setpopup(!popup);
  };

  const handleLogout = () => {
    setratingPopup(true);
  };

  const handleEmail = () => {
    setemailPopup(true);
  };

  return (
    <div
      className={`bg-white h-full flex  flex-col  p-4  sm:w-[30rem] w-[22rem] rounded-[30px] justify-end mb-4 overflow-hidden ${
        animate ? "animate-fade-in relative" : ""
      }`}
    >
      {LimitPopup && (
        <AlertMessage>
          <div className="flex flex-col">
          <h2 className="mb-2 font-light">The problem might not be getting resolved through the bot, please reach out to the real humans over at Mantis and they will help you out</h2>
          <button onClick={() => {setemailPopup(true);setLimitPopup(false)}} className="bg-black text-white  py-2 px-3 rounded-xl w-16">OK</button>
          </div>
        </AlertMessage>
      )}
      <div className="flex flex-col space-y-2 overflow-y-hidden container1  ">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`scroll-smooth  w-full font-main font-semibold flex flex-col pb-10 ${
              message.role === "assistant"
                ? "flex items-start"
                : "flex items-end"
            }`}
          >
            <div
              className={`max-w-[18rem] whitespace-pre-line ${
                message.role === "assistant"
                  ? "bg-white text-black rounded-lg p-2 px-4 msg-ass border-[1px] border-black "
                  : "bg-black text-white rounded-lg p-2 px-4 msg-user"
              }`}
            >
              <Linkify properties={{ style: { color: "#FFFFFF" } }}>
                {message.content}
              </Linkify>
            </div>
            <span className=" font-semibold text-[0.7rem] italic text-gray-600">
              {message.Timestamp.getHours() > 12
                ? message.Timestamp.getHours() - 12
                : message.Timestamp.getHours()}
              :{message.Timestamp.getMinutes()}
              {message.Timestamp.getHours() > 12 ? " pm" : " am"}
            </span>
          </div>
        ))}
        {isposting ? (
          <span className="animate-fade-in text-[0.5rem] font-bold font-main italic text-gray-300">
            Phteven is typing
            <span className="animate-ping text-black"> ...</span>
          </span>
        ) : (
          <></>
        )}
        {feedback ? (
          <span className="fixed flex justify-center gap-4  w-full bottom-24 left-1/2 transform -translate-x-1/2 ">
            <button
              className="border-2 border-red-700 px-2 py-1 rounded-2xl font-main text-sm font-semibold bg-white"
              onClick={handleEmail}
            >
              I Want a Real Human
            </button>
            <button
              className="border-2 border-black px-2 py-1 rounded-2xl font-main text-sm font-semibold bg-white"
              onClick={handleLogout}
            >
              End Conversation
            </button>
          </span>
        ) : (
          <></>
        )}
      </div>
      <div ref={scrollRef} className="h-[1px]"></div>
      <form onSubmit={handleSubmit} className=" items-center">
        <div className=" mt-4 flex gap-2 justify-between items-center border-black border-[1px] rounded-[20px] input-shadow pr-2 ">
          <input
            type="text"
            placeholder="Type your message..."
            className=" px-4 h-12 w-[90%] text-black rounded-[20px]"
            value={inputValue}
            onChange={handleInputChange}
          />
          <button type="submit" className="flex justify-center items-center">
            <img
              src={send}
              className="h-10 w-10 send-shadow hover:scale-110 "
              alt="Send"
            ></img>
          </button>
        </div>
      </form>
      <button
        onClick={handlePopup}
        className={`absolute right-4 top-4 h-6 w-6 ${
          !animate ? "invisible" : ""
        }`}
      >
        <img src={settings_btn} />
      </button>
      {popup ? (
        <div className="absolute right-12 top-8 rounded-[20px] p-4 border-solid border-[1px] border-black shadow-md h-max w-40 sm:w-80 sm:glass glass2">
          <Settings />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Chatbot;
