import axios from "axios";

export default async function fetchdata(authToken) {
  const data = {
    auth_token: authToken,
  };

  return axios
    .post(process.env.REACT_APP_API + "/admin/fetchdata", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "/",
      },
    })
    .then((response) => {
      console.log(response.data)
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}
