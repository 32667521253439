import React from 'react'
import { useChat } from '../Context/ChatProvider';

const Settings = () => {
  

  const { lang, setlang, changeLang, languages,setpopup,popup } = useChat();
  const handlePopup = () => {
    setpopup(!popup);
  };
  return (
    <>
    <div className='font-main text-xl font-semibold mx-2 mb-2 text-black'>Select Your Preffered Language <span className='text-xs font-light '> 🛈 Change this later anytime</span> </div>
    
    <div className='flex flex-wrap gap-2'>
        {languages.map((lng,index)=>{
            return(
                <button key={index} onClick={()=>{setlang(lng);changeLang(lng);handlePopup();}} className={lang===lng?'active-lang ':'inactive-lang'}>{lng}</button>
            )
        })}
    </div>
    </>
  )
}

export default Settings