import { useState } from "react";
import { useChat } from "../Context/ChatProvider";

const StarRating = () => {
  const { rating, setrating, setisrated } = useChat();
  const [hoverRating, setHoverRating] = useState(0);
  const handleMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const handleClick = (index) => {
    setrating(index);
    setisrated(true);
  };

  return (
    <div className="flex items-center">
      {[1, 2, 3, 4, 5].map((index) => (
        <span
          key={index}
          className={`text-2xl cursor-pointer m-1 my-2 ${
            hoverRating >= index || rating >= index
              ? "text-red-700"
              : "text-black"
          }`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(index)}
        >
          ★
        </span>
      ))}
    </div>
  );
};

export default StarRating;
