import React from "react";

const AlertMessage = ({ children }) => {
  return (
    <div className="fixed top-2 left-1/2 transform -translate-x-1/2">
      <div className="animate-fade-in bg-white font-semibold shadow p-4 rounded">
        {children}
      </div>
    </div>
  );
};

export default AlertMessage;