import getAuth from "../../Routes/Admin/Auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import loading from "../ui/assets/loading.svg";
import { useToast } from "../../Components/ui/use-toast";

const formSchema = z.object({
  password: z.string().min(6, {
    message: "Username must be at least 6 characters.",
  }),
});

export default function Login() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [passerror, setpasserror] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
    },
  });

  function onSubmit(credentials) {
    setIsLoading(true);
    getAuth(credentials)
      .then((response) => {
        localStorage.setItem("auth_token", response?.content);
        setIsLoading(false);
        navigate('/admin/dashboard');
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setpasserror(true);
          toast({
            title: "Authentication Error",
            description: "Incorrect password. Please try again",
            variant: "destructive",
          })
        } else {
          console.log("Login error:", error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <main className="w-screen h-screen flex  items-center gap-4 font-main">
      <section className="relative bg-black w-[40%] h-full  text-white font-semibold flex justify-center items-center">
        <h2 className=" absolute top-6 left-6 text-xl ">Mantis Tech</h2>
        <h2 className=" absolute bottom-6 left-6 text-xs font-light ">
          Copyright © 2015-2025 Mantis Tech, LLC. All rights reserved.
        </h2>
        <div className="flex flex-col gap-2">
          <span className="text-white text-4xl">Phteven AI</span>
        </div>
      </section>
      <section className=" w-[60%] flex flex-col justify-center items-center gap-12">
        <div className="flex flex-col justify-center items-center gap-4">
          <h1 className="text-4xl font-semibold">Log In</h1>
          <h2
            className={`text-sm ${
              passerror ? "text-gray-500" : "text-gray-500"
            }`}
          >
            Enter password to login to Phteven AI Dashboard
          </h2>
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-4 w-80"
          >
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder="Password"
                      type="password"
                      className={`${passerror && "border-red-600"}`}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="w-full"
              disabled={isLoading}
              variant={isLoading ? "secondary" : "default"}
            >
              {!isLoading ? "Log In" : "Authenticating"}
              {isLoading && (
                <img alt="loading" src={loading} className="w-6 h-6 ml-2 " />
              )}
            </Button>
          </form>
        </Form>
      </section>
    </main>
  );
}
