import React, { useEffect, useState } from "react";
import { sendemail } from '../Routes/chatRoutes'

const Popup = ({ setemailPopup, user_id, activeproduct, allmessages }) => {

  
  const [emailInput, setEmailInput] = useState("");
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  const msg1 = (
    <div className="text-center">
      We will email this chat over to the real folks over at{" "}
      <span className="text-red-700 font-semibold">Mantis</span> so that they
      may assist you. They will reach back to you as soon as possible. Is that
      fine?
    </div>
  );
  const msg2 = (
    <div className="flex flex-col gap-4 justify-center items-center">
      <div>Please Enter your email, we will reach back through email</div>
      <input
        type="email"
        className="border-[1px]  p-2 px-4 rounded-2xl "
        placeholder="your email here"
        value={emailInput}
        onChange={(e) => setEmailInput(e.target.value)}
        onKeyDown={handleKeyPress}
      ></input>
    </div>
  );
  const msg3 = (
    <div className="text-center">
      Our customer team will get in touch with you soon.
    </div>
  );
  const [content, setcontent] = useState(1);
  const contents = [msg1, msg2, msg3];

  function handleClick() {
    if (content + 1 <= contents.length) 
      next();
    else {
      setemailPopup(false);
    }
  }

  function handleNo() {
    setemailPopup(false);
  }

  function handleSend() {
    next();
    sendemail(user_id,emailInput,activeproduct,allmessages.current[activeproduct])
  }

  function next() {
    setcontent(content + 1);
  }

  // useEffect(() => {
  //   return () => {
  //     setpopupOpened(false);
  //   }
  // }, [])
  

  

  return (
    <div className="overlay h-screen w-screen absolute bg-overlay flex justify-center items-center z-30 font-main">
      <div className="Modal shadow-2xl border-[1px] border-solid h-max w-max sm:mx-0 mx-8 bg-white rounded-[20px]  flex flex-col justify-between sm:w-[20rem]  p-2">
        <div className="flex flex-col gap-2 m-2 mb-0 ">
          {contents[content - 1]}
        </div>
        <div className="flex justify-center">
          {content === 1 ? (
            <>
              <button
                onClick={handleClick}
                className="bg-black text-white m-1 mt-4 rounded-[15px] px-4 py-1 font-bold  text-sm"
              >
                Yes
              </button>
              <button
                onClick={handleNo}
                className="bg-black text-white m-1 mt-4 rounded-[15px] px-4 py-2 font-bold text-sm"
              >
                No
              </button>
            </>
          ) : content === 2 ? (
            <>
              <button
                className={`bg-black text-white m-1 mt-4 rounded-[20px] px-6 py-2 font-bold  shadow-md${
                  emailInput ? '' : 'opacity-50 cursor-not-allowed'
                }`}
                disabled={!emailInput}
                 onClick={handleSend}
              >
                Send
              </button>
            </>
          ) : (
            <>
              <button
                onClick={handleClick}
                className="bg-black text-white m-1 mt-4 rounded-[20px] px-6 py-2 font-bold "
              >
                Ok
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;
