import React, { useState } from 'react';
import StarRating from './StarRating';
import { setRating } from '../Routes/chatRoutes';
import { useChat } from '../Context/ChatProvider';

const Logoutpopup = ({user_id,activeproduct}) => {

  const {
   rating,setrating,feedback,setFeedback,setratingPopup,israted,setisrated
  } = useChat();


  const handleClose = () => {
    setRating(user_id,rating,feedback,activeproduct);
    setratingPopup(false);

  };


  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  return (
    <div className='font-semibold h-screen w-screen  absolute overlay z-10 flex justify-center items-center font-main'>
      <div className='border-[1px] shadow-2xl justify-center border-solid items-center h-max w-max sm:mx-0 mx-8 bg-white rounded-[30px]  flex flex-col sm:w-[20rem] sm:h-[16rem] p-6'>
        {!israted ? (
          <span>Please Rate your experience</span>
        ) : (
          <span>Please share your feedback (optional)</span>
        )}
        {!israted ? (
          <StarRating user_id={user_id} setisrated={setisrated} rating={rating} setrating={setrating} />
        ) : (
          <div>
            <textarea
              className='border-[1px] rounded-lg mt-2 p-2 font-normal text-gray-600'
              spellCheck='false'
              onChange={handleFeedbackChange}
              value={feedback}
            ></textarea>
          </div>
        )}
        {israted ? (
          <button
            onClick={handleClose}
            className={`${
              !israted ? 'bg-gray-300' : 'bg-black'
            } text-white m-2 mt-4 rounded-[20px] px-6 py-2 font-bold shadow-md`}
          >
            Submit Feedback
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Logoutpopup;
