import React from "react";
import ChatBottest from "./Chatbottest";
import Navbar from "./Navbar";
import Buttons from "./ProductButtons";
import Popup from "./Popup";
import Logoutpopup from "./Logoutpopup";
import Emailpopup from "./Emailpopup";
import { useEffect, useState } from "react";
import { useChat } from "../Context/ChatProvider";
import { getId } from "../Utils/Idgeneration";
import Cookies from "js-cookie";
import AlertPopup from "./AlertPopup";
import StarRatingTest from "./StarRatingTest";
import { editResponse } from "../Routes/chatRoutes";

const Main = ({ user_id, activeproduct, setactiveproduct }) => {
  const {
    isPopped,
    setisPopped,
    lang,
    setlang,
    animate,
    setanimate,
    ratingPopup,
    setratingPopup,
    emailPopup,
    setemailPopup,
    allmessages,
    messages,
    setMessages,
    setProduct,
    changeLang,
    languages,
    limitExceeded,
    setlimitExceeded,
  } = useChat();

  const [displayMessage, setDisplayMessage] = useState();
  const [responses, setresponses] = useState({User: "" , Assistant : ""});

  const handleRatingCompletion = () => {
    alert("Successfully rated response !");
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    editResponse(user_id,displayMessage,responses,activeproduct,event);
  };

  const handleChange = (event, role) => {
    setresponses((prev) => {
      return {...prev, [role]: event.target.value}; 
    });
  };

  useEffect(() => {
    setresponses(displayMessage);
  }, [displayMessage]);

  useEffect(() => {
    const limit_exceeded = Cookies.get("limit_exceeded");
    if (!limit_exceeded) getId(setactiveproduct, user_id);
    else {
      user_id.current = Cookies.get("user_id");
      setlimitExceeded(limit_exceeded);
    }
  }, []);

  return (
    <div className="flex justify-center items-center  bg-black h-screen flex-col sm:m-0 px-2 ">
      {/* <embed src="https://phtevenai-a4b6m.ondigitalocean.app/shipping" className="w-[25rem] p-4 h-[40rem] absolute right-0"></embed> */}
      {ratingPopup ? (
        <Logoutpopup
          user_id={user_id}
          setratingPopup={setratingPopup}
          activeproduct={activeproduct}
        />
      ) : (
        <></>
      )}
      {emailPopup ? (
        <Emailpopup
          user_id={user_id}
          setemailPopup={setemailPopup}
          activeproduct={activeproduct}
          allmessages={allmessages}
        />
      ) : (
        <></>
      )}
      {limitExceeded ? (
        <AlertPopup>
          <h2 className="font-medium capitalize ">
            Your message limit exceeded . Please try again after sometime
          </h2>
        </AlertPopup>
      ) : (
        <>
          {isPopped && (
            <Popup
              user_id={user_id}
              setisPopped={setisPopped}
              setlang={setlang}
              changeLang={changeLang}
              lang={lang}
              languages={languages}
              activeproduct={activeproduct}
              setProduct={setProduct}
              setanimate={setanimate}
            />
          )}
        </>
      )}
      <Navbar animate={animate} />
      <Buttons
        activeproduct={activeproduct}
        setProduct={setProduct}
        animate={animate}
      />
      <div className="h-[50rem] mb-2 flex justify-center gap-8">
        <ChatBottest
          messages={messages}
          allmessages={allmessages}
          activeproduct={activeproduct}
          setMessages={setMessages}
          user_id={user_id}
          lang={lang}
          animate={animate}
          setlang={setlang}
          changeLang={changeLang}
          languages={languages}
          setratingPopup={setratingPopup}
          setemailPopup={setemailPopup}
          setDisplayMessage={setDisplayMessage}
        />

        <div className="flex flex-col items-start justify-center gap-1">
          <form
            onSubmit={handleFormSubmit}
            className="bg-white text-black p-4 h-max w-96 rounded-3xl"
          >
            <label className="block mb-2 ">
              <span className="font-bold text-blue-700">User</span>
              <textarea
                value={responses?.User}
                onChange={(e)=>{handleChange(e,"User")}}
                className="form-textarea mt-1 block w-full border rounded-md p-2"
              />
            </label>
            <label className="block mb-2 ">
              <span className="font-bold text-red-700">Assistant</span>
              <textarea
                value={responses?.Assistant}
                onChange={(e)=>{handleChange(e,"Assistant")}}
                className="form-textarea h-32 mt-1 block w-full border rounded-md p-2"
              />
            </label>
            <button
              type="submit"
              className="bg-black text-white px-4 py-2 rounded-2xl"
            >
              Edit The Response
            </button>
          </form>

          <div className="bg-white rounded-2xl px-2 py-1">
            <StarRatingTest handleCompletion = {handleRatingCompletion} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
