import { useEffect, useState } from "react";
import fetchdata from "../../Routes/Admin/FetchData";
import { getmodel, setmodel } from "../../Routes/chatRoutes";
import AlertMessage from "../AlertMessage";
import { copyToClipboard, formatTimestamp } from "../../Utils/Misc";
import copy from "../../../src/Assets/copy.svg";
import open from "../../../src/Assets/open.png";
import loading from "../ui/assets/loading.svg";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Switch } from "../ui/switch"


const ChatHistory = ({ results, setresults }) => {
  const [allchats, setallchats] = useState();
  const [isAscending, setisAscending] = useState(false);
  const [expandedChats, setExpandedChats] = useState({});
  const [showPopupCopied, setShowPopupCopied] = useState(false);
  const [showPopupgptmodel, setShowPopupgptmodel] = useState(false);
  const [gptmodel, setgptmodel] = useState();
  const [sortoption, setsortoption] = useState("start_time");

  useEffect(() => {
    getmodel(setgptmodel);
    const authToken = localStorage.getItem("auth_token");
    if (!results) {
      fetchdata(authToken)
        .then((response) => {
          console.log(response);
          setresults(response);
          setallchats(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const expandChats = (userId) => {
    setExpandedChats((prevExpandedChats) => ({
      ...prevExpandedChats,
      [userId]: !prevExpandedChats[userId],
    }));
  };

  const handleModel = (value) => {
    setgptmodel(value);
    setShowPopupgptmodel(true);
    setmodel(value);
    setTimeout(() => {
      setShowPopupgptmodel(false);
    }, 2000);
  };

  useEffect(() => {
    if (results) {
      setallchats({ chats: sortChats(sortoption) });
    }
  }, [sortoption, isAscending]);

  // const filterChats = (attribute, value) => {
  //   return allchats
  //     ? allchats["chats"].filter((chat) => chat[attribute] === value)
  //     : results;
  // };

  const sortChats = (attribute) => {
    if (attribute === "start_time") {
      if (isAscending) return results["chats"].slice().reverse();
      else return results["chats"];
    }

    if (!isAscending)
      return allchats["chats"]
        .slice()
        .sort((a, b) => b[attribute] - a[attribute]);
    else
      return allchats["chats"]
        .slice()
        .sort((a, b) => a[attribute] - b[attribute]);
  };

  const attr_display = (key, value) => {
    if (value) {
      return (
        <span>
          <span className=" attr-head">{key}</span>
          <span className="attr-value">{value}</span>
        </span>
      );
    } else {
      return <></>;
    }
  };

  if (!results)
    return (
      <div className="h-[66rem] w-full flex justify-center items-center">
        <h2 className="font-semibold">Fetching Chats</h2>
        <img alt="loading" src={loading} className="w-6 h-6 ml-2 " />
      </div>
    );
  return (
    <div className="font-main font-light overflow-x-hidden">
      {showPopupCopied && <AlertMessage>Link copied</AlertMessage>}
      {showPopupgptmodel && (
        <AlertMessage>Changed gpt model to {gptmodel} </AlertMessage>
      )}
      <div className="chatscontainer px-2  flex flex-col gap-1 justify-center  items-center ">
        <div className="navbar  flex gap-4  my-6 items-center sm:w-[70%]">
          {/* <button
            onClick={handleSubmit}
            className="  bg-red-700 text-white px-2 py-1 rounded-xl font-semibold "
          >
            Refresh
          </button> */}

          {/* <button
          onClick={() => handleSort("session_time")}
          className="mx-2 my-2 bg-red-700 text-white px-2 py-1 rounded-xl font-semibold"
        >
          Sort by Start Time
        </button> */}
          <div className="flex gap-2 items-center">
            <span className="border-[1px] px-2 py-1 rounded-md font-semibold">
              Gpt Version
            </span>
            <Select
              defaultValue={gptmodel}
              onValueChange={(value) => handleModel(value)}
            >
              <SelectTrigger className="w-[180px] font-semibold">
                <SelectValue placeholder="Select Bot " />
              </SelectTrigger>
              <SelectContent className="font-semibold">
                <SelectItem value="3.5">3.5</SelectItem>
                <SelectItem value="4">4</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <Select
            defaultValue={sortoption}
            onValueChange={(value) => setsortoption(value)}
          >
            <SelectTrigger className="w-[180px] font-semibold">
              <SelectValue placeholder="Select Bot " />
            </SelectTrigger>
            <SelectContent className="font-semibold">
              <SelectItem value="start_time">Sort by Start Time</SelectItem>
              <SelectItem value="length">Sort by Length</SelectItem>
              <SelectItem value="rating">Sort by Rating</SelectItem>
            </SelectContent>
          </Select>
          <button
            onClick={() => {
              setisAscending(!isAscending);
            }}
            className="  border-[1px] px-2 py-1 rounded-md font-semibold shadow-sm "
          >
            Order : {isAscending ? "Asc" : "Desc"}
          </button>
        </div>

        {allchats?.chats?.map((user) => (
          <div
            className=" chat p-4 mb-4 rounded-xl w-full shadow-sm border-[1px] shadow-gray-300 bg-white h-max sm:w-[70%]"
            key={user._id}
          >
            <div className="text-black">
              <div className="flex justify-between">
                <span className="attributes grid grid-cols-3 gap-x-4 ">
                  {attr_display("Order ID", user?.order_id)}
                  {user?.tracking_id && (
                    <span>
                      <span className=" attr-head">Tracking ID</span>
                      <span className="attr-value">
                        <a
                          href={user?.tracking_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {user?.tracking_id}
                        </a>
                      </span>
                    </span>
                  )}
                  {attr_display("Zipcode", user?.zipcode)}
                  {attr_display("Start Time", user?.start_time)}
                  {attr_display("Rating", user?.rating)}
                  {attr_display("Feedback", user?.feedback)}
                  {attr_display("ChatGpt Model", user?.gpt_model)}
                  {attr_display("User Action", user?.action)}
                  {attr_display("User email", user?.useremail)}
                </span>
                <span className="flex flex-col items-end">
                  <div className="flex gap-4 justify-end items-center mt-2 mb-4">
                    <button
                      className=" rounded-xl font-semibold flex justify-center items-center bg-gray-200 p-[0.3rem]"
                      onClick={() =>
                        copyToClipboard(
                          `https://phtevenai.com/${user?.bot}/${user?.user_id}`,
                          setShowPopupCopied
                        )
                      }
                      title="Copy Chat Link to Clipboard"
                    >
                      <img src={copy} className="w-5 h-5" />
                    </button>
                    <button
                      className=" rounded-xl font-semibold flex justify-center items-center bg-gray-200 p-[0.3rem]"
                      title="Open Chat In New Window"
                    >
                      <a
                        href={`https://phtevenai.com/${user?.bot}/${user?.user_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={open} className="w-6 h-6" />
                      </a>
                    </button>
                  </div>
                  <div className=" px-2 py-1 rounded-2xl w-max text-red-700 font-bold">
                    {user?.language}
                  </div>
                  <div
                    className={`px-2 py-1 rounded-2xl w-max font-bold border-[1px] ${
                      user?.bot === "SHIP"
                        ? "border-blue-700 text-blue-700"
                        : "border-red-700 text-red-700"
                    }`}
                  >
                    {user?.bot === "SHIP" ? user?.carrier : user?.bot}
                  </div>
                </span>
              </div>
              {expandedChats[user._id] ? (
                <></>
              ) : (
                <div className=" flex  items-center text-gray-400 mr-4 mt-2">
                  <div className=" mr-2  text-xs rounded-full p-2 text-red-700  border-[1px] border-red-700 w-4 h-4 flex justify-center items-center">
                    {user?.length}
                  </div>
                  <span>{user?.chat[1]?.role} :</span>
                  <span>{user?.chat[1]?.content}</span>
                </div>
              )}
              <button
                className=" bg-red-700 text-white px-2 py-1 rounded-lg font-semibold mt-2 "
                onClick={() => expandChats(user?._id)}
              >
                {expandedChats[user?._id] ? "Collapse" : "View all"}
              </button>

              {expandedChats[user?._id] && (
                <div className="mt-2">
                  <ul className=" bg-gray-300 rounded-2xl p-2">
                    {user.chat.map((chatMessage, index) => (
                      <li key={index} className="text-white">
                        <div className="flex justify-between items-center">
                          <span className=" mb-2">
                            <span
                              className={`capitalize   px-2 py-1 rounded-xl font-bold ${
                                chatMessage.role == "user"
                                  ? "text-blue-800"
                                  : "text-red-700"
                              }`}
                            >
                              {chatMessage.role} :{" "}
                            </span>
                            {typeof chatMessage?.content === "string" ? (
                              <span className="text-sm font-semibold text-gray-700">
                                {chatMessage.content}
                              </span>
                            ) : (
                              <span
                                className="text-sm font-semibold text-gray-700"
                                dangerouslySetInnerHTML={{
                                  __html: chatMessage?.content,
                                }}
                              />
                            )}
                          </span>
                          <span className="text-xs text-gray-400">
                            {formatTimestamp(chatMessage?.Timestamp)}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatHistory;
