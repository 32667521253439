import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Components/Admin/Login";
import AdminDashboard from "./Components/Admin/AdminDashboard";
import DispChat from "./Components/DispChat";
import Main from "./Components/Main";
import Testing from "./Components/Testing";
import { useChat } from "./Context/ChatProvider";
import Shipping from './Components/Shipping'
import ShippingMain from "./Components/ShippingMain";
import ProtectedLayout from "./Components/Admin/ProtectedLayout";
import ChatHistory from "./Components/Admin/ChatHistory";
import { useState } from "react";


function App() {

  const [results, setresults] = useState();
  
  const {
    user_id,activeproduct,setactiveproduct
  } = useChat();

  return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/customer"
            element={
              <Main
                user_id={user_id}
                activeproduct={activeproduct}
                setactiveproduct={setactiveproduct}
              />
            }
          ></Route>
          <Route
            path="/"
            element={
              <Testing
                user_id={user_id}
                activeproduct={activeproduct}
                setactiveproduct={setactiveproduct}
              />
            }
          ></Route>
          <Route path="chathistory/:user_id/:bot" element={<DispChat />}></Route>
          <Route path="/shipping" element={<ShippingMain />}></Route>
          <Route path="/embed/shipping" element={<Shipping user_id={user_id} />}></Route>
          <Route path="/admin/login" element={<Login />}></Route>
          <Route element={<ProtectedLayout />}>
          <Route path="/admin/dashboard" element={<AdminDashboard results={results} setresults={setresults} />} />
          <Route path="/admin/chathistory" element={<ChatHistory results={results} setresults={setresults} />} />
        </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
