import React from 'react'
import ChatBot from "./Chatbot";
import Navbar from "./Navbar";
import Buttons from "./ProductButtons";
import Popup from "./Popup";
import Logoutpopup from './Logoutpopup'
import Emailpopup from './Emailpopup'
import { useEffect } from "react";
import { useChat } from '../Context/ChatProvider';
import { getId } from '../Utils/Idgeneration';
import Cookies from "js-cookie";
import AlertPopup from './AlertPopup';

const Main = ({user_id,activeproduct,setactiveproduct}) => {

  const {
    isPopped, setisPopped, 
    lang, setlang, 
    animate, setanimate, 
    ratingPopup, setratingPopup, 
    emailPopup, setemailPopup, 
    allmessages, 
    messages, setMessages, 
    setProduct, changeLang,
    languages,limitExceeded,setlimitExceeded
  } = useChat();

  useEffect(() => {
    const limit_exceeded = Cookies.get("limit_exceeded")
    if (!limit_exceeded)
     getId(setactiveproduct,user_id);
    else
     {
      user_id.current = Cookies.get("user_id");
      setlimitExceeded(limit_exceeded);
     }
  }, []);

  return (
    <div className="flex justify-center items-center bg-black h-screen flex-col  overflow-hidden sm:m-0 px-2 ">
      {ratingPopup ? (<Logoutpopup user_id={user_id} setratingPopup={setratingPopup} activeproduct={activeproduct}  />) : (<></>)}
      {emailPopup ? (<Emailpopup user_id={user_id} setemailPopup={setemailPopup} activeproduct={activeproduct} allmessages = {allmessages} />) : (<></>)}
            {limitExceeded ? (<AlertPopup><h2 className='font-medium capitalize '>Your message limit exceeded . Please try again after sometime</h2></AlertPopup>) : (<>
              {isPopped && (
              <Popup
                user_id={user_id}
                setisPopped={setisPopped}
                setlang={setlang}
                changeLang={changeLang}
                lang={lang}
                languages={languages}
                activeproduct={activeproduct}
                setProduct={setProduct}
                setanimate={setanimate}
              />
            )}                  
            </>)}
            <Navbar animate={animate} />
            <Buttons
              activeproduct={activeproduct}
              setProduct={setProduct}
              animate={animate}
            />
            <ChatBot
              messages={messages}
              allmessages={allmessages}
              activeproduct={activeproduct}
              setMessages={setMessages}
              user_id={user_id}
              lang={lang}
              animate={animate}
              setlang={setlang}
              changeLang={changeLang}
              languages={languages}
              setratingPopup ={setratingPopup}
              setemailPopup = {setemailPopup}
            />
          </div>
  )
}

export default Main