import React from 'react'

const ProductButtons = ({activeproduct,setProduct,animate}) => {
  return (
    <div className={`flex justify-center items-center text-white font-main sm:gap-8 gap-2 mb-4 text-sm sm:text-md  ${animate?'animate-fade-in-buttons':''}`}>
        <button className={activeproduct==="SPS"?'active-product':'inactive-product'} onClick={()=>{setProduct("SPS")}}>X2 , X3 , X10</button>
        <button className={activeproduct==="LA"?'active-product':'inactive-product'} onClick={()=>{setProduct("LA")}}>Laser Academy</button>
        <button className={activeproduct==="BB"?'active-product':'inactive-product'} onClick={()=>{setProduct("BB")}}>Blackbeard</button>
    </div>
  )
}

export default ProductButtons